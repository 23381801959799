import React from "react";
import { PageLayout } from "@components";
import { SectionHeader } from "./landingpagev2/_components/_Sectionheader/_Sectionheader";
import { Industries } from "./landingpagev2/_components/_Industries/_Industries";
import { Products } from "./landingpagev2/_components/_Products/_Products";
import BlogRollV3 from "@components/BlogRollV3";
import { graphql } from "gatsby";
import { ResourcesBlogPageProps } from "./blog";
import {
	blogNodeToCmsNode,
	filterBlogCategoriesForEnv,
} from "@components/CMS/CmsTypes";
import { FeatureSummary } from "./landingpagev2/_components/_FeatureSummary/_FeatureSummary";
import { PartnersV2 } from "./landingpagev2/_components/_Partners/_Partners";
import { HeroV2 } from "./landingpagev2/_components/_HeroV2/_HeroV2";
import JoinLeadersTestimonials from "@components/JoinLeadersTestimonials/JoinLeadersTestimonials";
import ResearchTeam from "./landingpagev2/_components/_ResearchTeam/_ResearchTeam";
import * as style from "./index.module.scss";
export default function LandingPageV2({ data }: ResourcesBlogPageProps) {
	const { blogs } = data;

	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "why-genemod" }}
			fixedHeaderColor
		>
			<HeroV2 />
			<PartnersV2 />
			<Products />
			<FeatureSummary />
			<ResearchTeam />
			<Industries />
			<JoinLeadersTestimonials testimonials={AdsCustomerQuotes} joinInstitutionContainerClass={style.joinInstitutionContainerClass} joinInstitutionBodyClass={style.joinInstitutionBodyClass} />
			<div className={style.blogSection}>

			<SectionHeader type="news" className={style.newsSectionHeader} outerContainerClassName={style.outerContainerClassName}/>
			<BlogRollV3
				blogs={blogs.nodes
					.filter(filterBlogCategoriesForEnv)
					.map(blogNodeToCmsNode)}
					/>
					</div>

		</PageLayout>
	);
}

const AdsCustomerQuotes = [
	{
		quote: `“User-friendly interface, powerful features, and commitment to security and compliance make it a standout choice in the competitive landscape of laboratory software.”`,
		author: "Bhavya V.",
		authorTitle: "Research Scientist",
	},
	{
		quote: `“My favorite aspect of Genemod is the dynamic inventory storage system which enables you to walk through freezers and boxes digitally.”`,
		author: "Christopher O.",
		authorTitle: "Research Scientist",
	},
	{
		quote: `“Genemod allows our company to manage inventory data end-to-end in one single platform. It is very easy to use and has greatly improved our efficiency.”`,
		author: "Ryan K.",
		authorTitle: "Sr. Scientist",
	},
];
export const pageQuery = graphql`
	{
		blogs: allNodeBlog(sort: { fields: created, order: DESC }) {
			nodes {
				title
				body {
					processed
					summary
				}
				created(formatString: "MMMM DD, YYYY")
				path {
					alias
				}
				relationships {
					field_blog_category {
						name
					}
					field_blog_feature_image {
						relationships {
							field_media_image {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 684)
									}
								}
							}
						}
						field_media_image {
							alt
						}
					}
				}
			}
		}
	}
`;
