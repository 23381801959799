import GENEMOD_FREEZER from "@assets/products/landing/freezer.mp4";
import React, { useRef } from "react";
import * as styles from "./_HeroV2.module.scss";
import { HeroV2 as HeroComponent } from "@components/HeroV2/HeroV2";
export const HeroV2 = () => {
	const videoRef = useRef<HTMLVideoElement>(null);

	const props = {
		className: styles.heroContainer,
		heroTextContainerStyle: styles.heroTextContainer,
		title: "Modern software for scientific research",
		description:
			"All of your work in one place: inventory, experiments, protocols, and more.",
		image: (
			<div className={styles.videoWrapper}>
				<video
					ref={videoRef}
					className={styles.videoContainer}
					muted
					autoPlay
					loop
					playsInline
					preload="auto"
				>
					<source src={GENEMOD_FREEZER} type="video/mp4" />
				</video>
			</div>
		),
	};

	return (
			<HeroComponent {...props} />
	);
};
