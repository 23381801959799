import React from "react";
import * as styles from "./_FeatureSummary.module.scss";
import { TypographyV2 } from "@components/index";
import { brandedText } from "@components/TypographyV2/TypographyV2";
import { StaticImage } from "gatsby-plugin-image";

export const FeatureSummary = () => {
	const HEADER_TEXT = brandedText`Modern software for streamlining scientific research`;
	const CONTENT = [
		"Digitize research workflows in minutes",
		"Visualize inventory and centralize everything",
		"Manage all the projects in a single notebook",
		"Let AI help you on the reports",
	];

	return (
		<div className={styles.outerContainer}>
			<div className={styles.centerImage}>
				<FeatureSummaryImage />
			</div>
			<div className={styles.innerContainer}>
				<div
					className=""
					style={{
						display: "flex",
					}}
				>
					<TypographyV2
						variant="HEADING_2"
						mobileVariant="HEADING_2"
						tabletVariant="HEADING_2"
						weight="REGULAR"
						className={styles.headerText}
					>
						{HEADER_TEXT}
					</TypographyV2>
				</div>
				<div className={styles.contentContainer}>
					{CONTENT.map((text) => (
						<div className={styles.textItemContainer}>
							<div className={styles.starImage}>
								<StarPatternV2 />
							</div>

							<TypographyV2
								color="text-helper"
								variant="BODY_TEXT_EXTRA_LARGE"
								tabletVariant="BODY_TEXT_EXTRA_LARGE"
								mobileVariant="BODY_TEXT_EXTRA_LARGE"
								weight="REGULAR"
								className={styles.text}
							>
								{text}
							</TypographyV2>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const FeatureSummaryImage = () => (
	<StaticImage
		loading="eager"
		src="../../_assets/dashboard-widget4x.png"
		alt="Feature Summary"
	/>
);

function StarPatternV2() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
		>
			<path
				d="M12 2.5L14.012 7.73109C14.294 8.46432 14.435 8.83093 14.6542 9.13931C14.8486 9.41262 15.0874 9.65141 15.3607 9.84575C15.6691 10.065 16.0357 10.206 16.7689 10.488L22 12.5L16.7689 14.512C16.0357 14.794 15.6691 14.935 15.3607 15.1542C15.0874 15.3486 14.8486 15.5874 14.6542 15.8607C14.435 16.1691 14.294 16.5357 14.012 17.2689L12 22.5L9.98804 17.2689C9.70603 16.5357 9.56503 16.1691 9.34575 15.8607C9.15141 15.5874 8.91262 15.3486 8.63931 15.1542C8.33093 14.935 7.96432 14.794 7.23109 14.512L2 12.5L7.23108 10.488C7.96431 10.206 8.33093 10.065 8.63931 9.84575C8.91262 9.65141 9.15141 9.41262 9.34575 9.13931C9.56503 8.83093 9.70603 8.46431 9.98804 7.73108L12 2.5Z"
				stroke="#F67416"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
